import { ref, watchEffect } from "vue"
import { projectFirestore } from "../firebase/config"

const getDocument = (collection, id) => {

    const error = ref(null)
    const document = ref(null)

    let documentRef = projectFirestore.collection(collection)
        .doc(id)

    const unsub = documentRef.onSnapshot(doc=>{
        if(doc.data()){
            document.value = {...doc.data(),id:doc.id}
            error.value = null
        }else{
            error.value = 'Document not exist'
        }

    }, (err) => {
        console.log(err.message)
        error.value = "Couldn't fetch document "
    })

    watchEffect((onInvalidate)=>{
        onInvalidate(() => unsub() )
    })

    return {error, document}

}

export default getDocument