<template>
  <Navbar/>
    <div v-if="blogs" class='pb-3 '>
      <div v-for="(blog, index) in filteredBlog" :key="index" class='container d-flex ' style="background-position:center center; background-size:cover">
        <div class='row py-3 py-md-5'>
          <div class="col-sm-4 col-12" :class="(index % 2 == 0 ? 'order-1' : 'order-2')" >
            <router-link :to="{name:'BlogDetail', params:{id: blog.id}}">
              <div v-if="blog.coverUrl" class='blog-img' :style="`background-image:url(${blog.coverUrl})`" style="background-size: cover; background-position:center;"></div>
              <div v-else class='h-100 blog-img' :style="`background-image:url('https://dummyimage.com/1200x500/ccc/ccc')`" style="background-size: cover; background-position:center;"></div>
            </router-link>
          </div>
          <div class='col-sm-8 col-xs-6 px-lg-5 px-md-4' :class="(index % 2 == 1 ? 'order-1' : 'order-2')">
            <span class="badge alert-info mb-3" v-for="(hashtag, idx) in blog.hashtags" :key="idx">{{hashtag}}</span>
            <router-link :to="{name:'BlogDetail', params:{id: blog.id}}">
              <h1 class='cover-heading text-left '>{{ blog.title }}</h1>
            </router-link>
            <p class='lead justify-content-start'>
              {{ (blog.shortDescription == '' ? blog.blogContent.substring(0,250) : blog.shortDescription) }}
              <router-link :to="{name:'BlogDetail', params:{id: blog.id}}" style="font-size:12px">...read more</router-link> 
            </p>
          </div>
          
        </div>
        
      </div>
    </div>

  <Footer/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getCollection from '@/composables/getCollection'
import { computed } from '@vue/runtime-core'

export default {
    props:['hashtagList'],
    components: {
        Navbar, Footer
    },
    setup(props) {
        const {documents:blogs} = getCollection('blogs')

        //=================harus di edit blm beres=============
        const filteredBlog = computed(()=>{
            // return blogs.value.filter(blog => blog.status_publish)
            if (blogs.value !== null && blogs.value !== undefined  ) {
              return blogs.value.filter((blog) => {
                  if (blog.status_publish) {
                      return blog
                  }
              })
            }
        })

        return { blogs, filteredBlog }
      
    }
}
</script>
<style scoped>
.blog-img{
  min-height: 250px;
}

.container{
  max-width: 100%;
}

.container:nth-child(2n){
  background: #4d8089;
  color: white;
}

.container:nth-child(2n) a{
  color: orange;
}

.container a:hover{
  text-decoration: none;
}

.container h1{
  color: black;
}

.container h1:hover{
  color:grey;
}

.container:nth-child(2n) h1{
  color: white;
}

.container:nth-child(2n) h1:hover{
  color: wheat;
}
  

.container:nth-last-child(){
  padding-bottom: 50px;
}
</style>