import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Gallery from '@/views/Gallery.vue'
import Destination from '@/views/Destination.vue'
import DestinationDetail from '@/views/DestinationDetail.vue'
import Blog from '@/views/Blog.vue'
import BlogDetail from '@/views/BlogDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
    props:true
  },
  {
    path: '/destination',
    name: 'Destination',
    component: Destination,
    props:true
  },
  {
    path: '/destination/:id',
    name: 'DestinationDetail',
    component: DestinationDetail,
    props:true
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    props:true
  },
  {
    path: '/blog/:id',
    name: 'BlogDetail',
    component: BlogDetail,
    props:true
  },
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
