<template>
  <Navbar/>
        <div v-if="blog && blog.coverUrl" class='cover-container p-3' id='mainBG' :style="`
            background-image:url(${blog.coverUrl}); 
            background-position:center center; 
            background-size:cover`">
            <div class='row'>
            </div>
        </div>
        <div v-else class='cover-container p-3' id='mainBG' :style="`
            background-image:url('https://dummyimage.com/1200x500/ccc/ccc'); 
            background-position:center center; 
            background-size:cover`">
            <div class='row'>
            </div>
        </div>
        
        <div v-if="blog" class="container pb-5" id='blog-container'>
            <div class='row'>
                <div class='blog-title col-12 p-3'>
                    <span class="badge alert-info mb-3">Culture</span>
                    <h1 class='cover-heading text-left display-4'>{{ blog.title }}</h1>
                </div>
            </div>

            <div class='row'>
                <div class='col-sm-12'>
                    <p class='text-justify' v-html="blog.blogContent">
                    </p>
                </div>
            </div>
        </div>
    <ContactBar v-if="setting" :setting="setting"/>
    <Footer v-if="setting" :setting="setting"/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import ContactBar from '@/components/ContactBar.vue'
import getDocument from '@/composables/getDocument'

export default {
    props:['id'],
    components: {
        Navbar, Footer, ContactBar
    },
    setup(props) {
        const { document:blog } = getDocument('blogs',props.id)
        const { document:setting } = getDocument('setting','contactSetting')

        return {
            blog, setting 
        }
    }
}
</script>
<style scoped>
#mainBG{
    height: 50vh;
}

    
</style>