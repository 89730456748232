<template>
    <Navbar/>
    <div v-if="destination" class='cover-container h-100 p-3' :style="`
            background-image:url(${destination.coverUrl});
            background-position:bottom; 
            background-size:cover;
            position:relative`"
    >
        <div class='row'>
            <div class='col-12 text-center destination-main'>

                    <div class='destination-main-text'>
                        <!-- EXPERIENCE -->
                    </div>
                    <div class='destination-main-title font-utama'>
                        {{destination.name}}
                    </div>
                    <div class="tagline">{{ destination.hashtags.join(' ') }}</div>
            </div>
        </div>
        <a href="#contactPre" class='contact-us-btn contact-us'>CONTACT US <i class="fa fa-arrow-down fa-beat"></i></a>
    </div>

    <section class="pt-5">
        <h2 class="text-center">Stories</h2>
        <div class='row my-5' v-if="blogs">
            <div class="col-6 col-sm-4 col-md-4"  v-for="(blog, index) in filteredBlog" :key="index">
                <div class="card mb-3" >
                    <img v-if="blog.coverUrl" class="card-img-top" :src="blog.coverUrl" :alt="blog.title">
                    <img v-else class="card-img-top" src="https://dummyimage.com/400x280/ccc/ccc" :alt="blog.title">
                    <div class="card-body">
                        <h5 class="card-title">{{blog.title}}</h5>
                        <p class="card-text">{{ (blog.shortDescription == '' ? blog.blogContent.substring(0,100) : blog.shortDescription.substring(0,100)) }}</p>
                        <router-link class="btn btn-primary" :to="{name:'BlogDetail', params:{id: blog.id}}" style="font-size:12px">read more</router-link>                     
                    </div>
                </div>
            </div>

        </div>
    </section>

    <ContactBar v-if="setting" :setting="setting"/>
    <Footer/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import ContactBar from '@/components/ContactBar.vue'
import { computed, onUpdated, ref } from '@vue/runtime-core'

export default {
    props:['id'],
    components: {
        Navbar, Footer, ContactBar
    },
    setup(props) {
        const { documents:blogs } = getCollection('blogs')
        const { document:destination } = getDocument('destination',props.id)
        const { document:setting } = getDocument('setting','contactSetting')
        const filteredBlog = computed(()=>{
            if (destination.value && blogs.value && blogs.value != null) {
                let hashtagList = destination.value.hashtags
                hashtagList.push('#travelinfo','#traveltips','#general')
                
                // blogs.value.map((blg)=>{
                //     console.log(blg.username)
                // })

                return blogs.value.filter((blog) => {
                    let isThere = false;
                    let ht = blog.hashtags
                    for (let i = 0; i < ht.length; i++) {
                        console.log(hashtagList.includes(ht[i]))
                        console.log(hashtagList,ht[i])
                        if (hashtagList.includes(ht[i])) {
                            isThere = true
                        }
                    }
                    if (isThere) {
                        return blog
                    }
                });
                
            }
        })

        return {destination, setting, blogs, filteredBlog}
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');
.font-utama{
    font-family: 'Shadows Into Light', cursive;
}

.destination-main-title{
    font-size: 5em;
    text-shadow: 2px 2px #ddd;
    text-transform: uppercase;
}

.destination-main-text{
    font-size: 1.5em;
}

.destination-main{
    /* position: relative; */
    margin: auto;
    color: #fff;
    margin-top: 25%;
    text-align: center;
    position: relative;
}

.h-100{
    height: 100vh !important;
}

.contact-us-btn:hover{
    background: #4d8089;
    font-size: 1em;
    cursor: pointer;
    color: white;
}


.contact-us-btn{
    /* background: #ddd; */
    background: transparent;
    border: none;
    border-radius: 10px;
    padding: 6px 26px;
    font-weight: 100;
    font-size: 1em;
}

.contact-us{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

@media (orientation: portrait) {
    .destination-main{
        margin-top: 50%;
    }
    .contact-us{
        bottom: 20px;
    }
}

@media (max-width: 576px) {
    .destination-main-title{
        font-size: 3em;
        letter-spacing: 5px;
    }
}

@media (min-width: 768px) {
    .destination-main-title{
        letter-spacing: 10px;
    }
}
</style>