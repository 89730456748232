<template>
  <!--- Footer -->

  <footer>
    <div class='container padding'>
      <div class='row text-left padding'>
        <div class='pt-4 pb-2 py-sm-4 col-md-4 col-12'>
          <h5>Navigation</h5>
            <router-link class="py-0 px-sm-0 nav-link"  to="/contact">Contact</router-link>
            <router-link class="py-0 px-sm-0 nav-link"  to="/gallery">Gallery</router-link>
            <router-link class="py-0 px-sm-0 nav-link"  to="/about">About</router-link>
            <router-link class="py-0 px-sm-0 nav-link"  to="/blog">Blog</router-link>
        </div>
        <div class='py-1 py-sm-4 col-md-4 col-12'>
          <h5>FAQ</h5>
        </div>
        <div class='py-1 py-sm-4 col-md-4 col-12'  v-if="setting" >
          <h5 class='d-sm-block'>Meet Our Social Media</h5>
          <div class='social'  v-if="setting">
            <a target="_blank" v-if="setting.facebookUrl" :href="setting.facebookUrl" class="pr-2"><i class="fa fa-facebook"></i></a>
            <a target="_blank" v-if="setting.twitterUrl" :href="setting.twitterUrl" class="pr-2"><i class="fa fa-twitter"></i></a>
            <a target="_blank" v-if="setting.instagramUrl" :href="setting.instagramUrl" class="pr-2"><i class="fa fa-instagram" style="color:black"></i></a>
            <a target="_blank" v-if="setting.youtubeUrl" :href="setting.youtubeUrl" class="pr-2"><i class="fa fa-youtube-play" style="color:red"></i></a>
            <a target="_blank" v-if="setting.tripadvisorUrl" :href="setting.tripadvisorUrl" class="pr-2"><i class="fa fa-tripadvisor" style="color:red"></i></a>
          </div>
        </div>
        <div class='py-4 py-sm-4 col-12'>
          <!-- <hr class="light-100"> -->
          <h6>
            &copy; 2020 GOONG2000.com
          </h6>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from '@vue/reactivity'

export default {
  name: 'Footer',
  props:['setting'],
  setup(props, context) {
    
    console.log(props)
    const setting = ref(props.setting)
    return {setting}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row{
  max-width: 100%;
}

.container{
  padding:20px 0px;;
}

footer{
  background-color: #4d8089;
  color: #fff;
}

.nav-link{
  text-decoration: none;
  color: inherit;
}

h5{
  font-weight: 700;
}

</style>
