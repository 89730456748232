<template>
    <div class='container pt-5 pb-3 px-sm-3' style="background-position:center center; background-size:cover">
            <div class='px-1'>
                <button type="button"  v-for="tag in tags" :key="tag" @click="handleClick(tag)" class="btn btn-lg btn-outline-info mx-1 my-2">
                    {{ tag }} <i :id="`btn-${tag}-icon`" class='fa fa-check'></i>
                </button>
            </div>
    </div>
</template>
<script>
import getCollection from '@/composables/getCollection'
import { ref } from '@vue/reactivity';

export default {
    props:['images'],
    setup(props, context) {
        const tagList = new Set()
        const tags = ref([]) 
        const selectedTag = ref([])

        const {documents:blogs} = getCollection('blogs')

        if (props.images) {
            props.images.forEach(item => {
                item.tags.forEach(tag => tagList.add(tag));
            });
        }
        
        tags.value = [...tagList]

        // console.log(tagList);

        const handleClick = (tag) => {
            event.target.classList.toggle('selectedTag')
            var x = document.getElementById(`btn-${tag}-icon`);
            if (x.style.display === "none") {
                x.style.display = "inline-block";
            } else {
                x.style.display = "none";
            }
            
            if (selectedTag.value.includes(tag)) {
                selectedTag.value = selectedTag.value.filter(item => item !== tag)
            }else{
                selectedTag.value.push(tag)
            }
            context.emit('tag',selectedTag)
        }

        return{tags, handleClick}
    }
    
}
</script>
<style scoped>
    button{
        margin-right : 8px;
    }


    .tagContainer{
        margin: 25px 0px;
        padding: 20px;
        position: relative;
    }
    
    
</style>