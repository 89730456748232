import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3v7vSrwasu-f1mm9ROXD-Ptw8TGOkQV0",
    authDomain: "goong2000.firebaseapp.com",
    projectId: "goong2000",
    storageBucket: "goong2000.appspot.com",
    messagingSenderId: "593869363969",
    appId: "1:593869363969:web:82326e1d1008da356b9900",
    measurementId: "G-G170SR1CPE"
  };

//init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const projectStorage = firebase.storage()
const projectFunctions = firebase.app().functions('asia-southeast2')

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, projectStorage, timestamp, projectFunctions }