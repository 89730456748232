<template>
  <!--- One Column Section -->
  <Navbar/>
  <div style="height:100vh;">
    <div v-if="!landing" id="landingLoader">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <span v-if="landing">
      <div id="mainBG" class='cover-container' 
        :style="`
            background-image:url(${landing.heroImage}); 
            background-position:right center; 
            background-size:cover;
            overflow: hidden;
            height:100vh;
        `"
        >
        <div class='row'>
        <div class=''>
            <p class='lead text-center'>
            </p>
        </div>
        </div>
      </div>
    </span>
  </div>
  <div class="cover-container pb-5 px-sm-1 pb-md-1 mt-3" id="howToContainer" >
    <div class="container">
      <div class="row pb-5" >
        <div class='col-sm-12 text-center'>
          <h1 class=" p-2 pb-4 p-sm-4">How To</h1>
        </div>
        <div class='col-sm'>
          <div class="d-flex justify-content-center how-to-icon">
            <img src="../assets/custom_icon/multiple_place.svg" class="align-self-center" />
          </div>
          <div class='text-center px-5 py-3 px-sm-2 pt-md-3'>
            <h4>Choose Destination</h4>
            <p>First, choose your destination(s). Each destination will be specially crafted/designed for you. Each place has its own uniqueness.</p>
          </div>
        </div>
        <div class='col-sm'>
          <div class="d-flex justify-content-center how-to-icon">
            <img src="../assets/custom_icon/calendar.svg"  class="align-self-center" />
          </div>
          <div class='text-center px-5 py-3 px-sm-2 pt-md-3'>
            <h4>Choose Travel Period</h4>
            <p>Second step is to tell us your travel period, again, no worries if you limited time, we will help you to arrage to the way you enjoy it fully </p>
          </div>
        </div>
        <div class='col-sm'>
          <div class="d-flex justify-content-center how-to-icon">
            <img src="../assets/custom_icon/list.svg"  class="align-self-center" />
          </div>
          <div class='text-center px-5 py-3 px-sm-2 pt-md-3'>
            <h4>Notes Specific Request</h4>
            <p>If you have any specific request or bucket list, please tell us, we will gladly to include it to your itinerary.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!setting" id='footerLoader'>
    <i class="fa fa-spinner fa-spin"></i>
  </div>
  <ContactBar v-if="setting" :setting="setting"/>
  <Footer v-if="setting" :setting="setting"/>
  <!-- <div class="home">
    <p>Homepage</p>
    <img alt="Vue logo" src="../assets/logo.png">
  </div> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import ContactBar from '@/components/ContactBar.vue'
import getDocument from '@/composables/getDocument'
import { ref } from '@vue/reactivity'
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  components: {
    ContactBar,
    Navbar,
    Footer
  },
  setup(props) {
    const id = "W9las9dNCjotMEpxakZX"
    const { document:landing } = getDocument('landing-page',id)
    const { document:setting } = getDocument('setting','contactSetting')
    console.log(setting)
    
    return { landing, setting }
  }
}
</script>
<style scoped>
#howToContainer{
  background-image:url('../assets/custom_icon/chair-umbrella.png'); 
  background-position:left bottom;
  background-repeat: no-repeat;
  background-size: 150px;
}

.how-to-icon {
  height: 180px;
  width: 180px;
  margin: auto;
  border-radius: 50%;
  background-color: #a3d9f6;
}

.how-to-icon img{
  width: 65px;
}

#footerLoader, #landingLoader{
  text-align: center;
  font-size: 40px;
}

#landingLoader{
  display: block;
  padding-top: 35vh
}

#footerLoader{
  padding: 40px;
}

</style>
