<template>
    <div id="picture-modal" >
        <div class="content">
            <div class="content-inner">
                <picture class="img-wrap">
                    <img id='imgshow' :src="props.imageUrl[pictIndex]" alt="">
                </picture>
                <button id='close-1' class='lg-close' @click="closeModal()"></button>
                <button id='magnify-1' class='lg-magnify' @click="magnifyPicture()"><i class='fa fa-search'></i></button>
                <div class="lg-actions">
                    <div class="prev" @click="prevImg()"></div>
                    <div class="next" @click="nextImg()"></div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
    props:['imageUrl', 'pictIndex'],
    setup(props,context) {
        const imageUrl = ref('')
        const isMagnify = ref(false)
        const closeModal = () => {
            context.emit('close',false)
        }
        const pictIndex = ref(props.pictIndex)

        const magnifyPicture = () => {
            // console.log(isMagnify.value)
            let height = document.getElementById("imgshow").offsetHeight
            let width = document.getElementById("imgshow").offsetWidth
            if(isMagnify.value){
                document.getElementById("imgshow").style.maxHeight = (height/1.5)+'px'
                document.getElementById("imgshow").style.maxWidth = (width/1.5)+'px'
            }else{
                document.getElementById("imgshow").style.maxHeight = (height*1.5)+'px'
                document.getElementById("imgshow").style.maxWidth = (width*1.5)+'px'
            }

            isMagnify.value = !isMagnify.value
        }

        const nextImg = () => {
            if (pictIndex.value < props.imageUrl.length - 1) {
                pictIndex.value++
            }else{
                pictIndex.value = 0
            }
        }

        const prevImg = () => {
            if (pictIndex.value > 0 ) {
                pictIndex.value--
            }else{
                pictIndex.value = props.imageUrl.length-1
            }
        }
        return{
            props, closeModal, magnifyPicture, nextImg, prevImg, pictIndex
        }
    }
}
</script>
<style scoped>


    #picture-modal{
        background-color:#000 ;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1026;
    }

    #close-1{
        position: absolute;
        top: 20px;
        right: 30px;
        background: none;
        color: white;
        border: none;
        cursor: pointer;
    }

    #magnify-1{
        position: absolute;
        top: 20px;
        right: 80px;
        background: none;
        color: white;
        border: none;
        cursor: pointer;
        display: none;
    }

    #magnify-1:hover{
        font-size: 1.1em;
    }

    #close-1:hover{
        font-size: 1.1em;
    }

    .lg-close::after{
        content: 'X';
    }

    .content{
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .content-inner{
        position: relative;
        left: 0;
        height: 100vh;
        width: 70%;
        margin: auto;
        overflow-y: auto;
    }


    .img-wrap{
        position: absolute;
        margin: auto;
        text-align: center;
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .img-wrap img{
        max-height: 100%;
        max-width: 100%;
        width:auto !important;
        height: auto !important;
        display: inline-block;
        vertical-align: middle;
    }

    .lg-actions .next, .lg-actions .prev{
        background-color:rgba(0,0,0,.45);
        border-radius: 2px;
        color:#999;
        cursor: pointer;
        display: block;
        font-size:30px;
        padding: 0px 8px 5px 8px;
        position: absolute;
        top: 48%;
        z-index: 1089;
        border: none;
        font-weight:bold;
    }

    .lg-actions .next{
        right: 20px;
    }

    .lg-actions .prev{
        left: 20px;
    }

    .lg-actions .next::before{
        content: "\2192";
    }
    
    .lg-actions .prev::after{
        content: "\2190";
    }

    @media screen and (max-width:375px) {
        .content-inner{
            width: 100%;
        }

        .img-wrap img{
        max-height: 85vh;
        max-width: 100vw;
    }
    }
</style>