import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/css/style.css'
import './assets/css/style-mq.css'

// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-thumbnail.css';
// import 'lightgallery/css/lg-zoom.css';


const app = createApp(App).use(router).mount('#app')
