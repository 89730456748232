<template>
  <Navbar/>
    <div>
        <div v-if="images">
            <TagList :images="images" @tag='handleFilter($event)' />
        </div>
        
        <div class='container p-4 px-sm-3 mx-auto' style="background-position:center center; background-size:cover">
            <div class='card-columns pb-5 px-2 px-sm-1'>
                <div v-for="(gallery, index) in filteredGallery" 
                    :key="index" 
                    @click="showPicture(gallery.imageUrl, index)"
                    class="card gallery-item"
                    >
                    <img class="card-img" loading="lazy" :src="gallery.imageUrl" />
                </div>

            </div>
        </div>
        
    </div>
    <PictureModal v-if="showModal" :imageUrl='imageUrl' :pictIndex='pictIndex' @close='showModal = false' />
  <Footer/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import HeaderImage from '@/components/HeaderImage.vue'
import getCollection from '@/composables/getCollection'
import TagList from '@/components/TagList.vue'
import PictureModal from '@/components/PictureModal.vue'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'

export default {
    components: {
        Navbar, Footer, HeaderImage, TagList, 
        PictureModal
    },
    setup(props) {
        const tagList = ref([])
        const showModal = ref(false)
        const imageUrl = ref([]);
        const handleFilter = (tags) => {
            tagList.value = tags.value
        }
        const pictIndex = ref(0)
        const {documents:images} = getCollection('gallery')

        const filteredGallery = computed(()=>{
            imageUrl.value = []
            if (!tagList.value.length) {
                if(images.value){
                    images.value.forEach(item=>{
                        imageUrl.value.push(item.imageUrl)
                    }) 
                }
                return images.value
            }else{
                return images.value.filter((item) => {
                    var match = 1;
                    tagList.value.forEach(tag => {
                        if (item.tags.includes(tag)) {
                            match = 0
                        }
                    });
                    if(match){
                        imageUrl.value.push(item.imageUrl)
                    }
                    return match
                })
            }
        })

        const showPicture = (url, index) =>{
            // imageUrl.value = url
            showModal.value = true
            pictIndex.value = index
        }

        return {
          filteredGallery, images, handleFilter, showModal, imageUrl, showPicture, pictIndex
        }

      
    }
}
</script>

<style scoped>
.card-img{
    cursor:pointer;
}
</style>
