<template>
  <Navbar/>
    <div style="background-color: #edf0f5;">
        <div class="content d-flex justify-content-md-center ">
            <div class='m-lg-5 m-sm-5'>
                <div class="card" id="contact-us-container">
                    <div style='max-height: 250px;overflow: hidden;' >
                        <img class="card-img-top" src="../assets/home/beach-05.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body p-sm-4" style='background-color: #fff;'>
                        <h2 class="px-2 pt-3 px-sm-0 pt-sm-0 card-title mb-0 display-5">Get In Touch</h2>
                        <p class="px-2 pb-3  px-sm-0 pb-sm-0 card-text mb-4 ">Via <a id='whatsappLink' class="btn btn-xs btn-success" href='#' ><i class='fa fa-whatsapp'></i> </a> or send a message by filling form below</p>
                        <form class='p-2 p-sm-0' id="contact-us-official-form" method='POST' action='home/sendContactUs'>
                            <div class='form-row'>
                                <div class="col-12 mx-0 my-2 p-0">
                                    <input class='form-control border-top-0 border-left-0 border-right-0' name='name' placeholder="Name*">
                                </div>
                                <div class="col-xs-12 col-sm-6 mx-0 my-2 pr-sm-3 p-0">
                                    <input class='form-control border-top-0 border-left-0 border-right-0' type='email' name='email' placeholder="Email*">
                                </div>
                                <div class="col-xs-12 col-sm-6 mx-0 my-2 p-0">
                                    <input class='form-control border-top-0 border-left-0 border-right-0' name='phone' placeholder="Phone / Whatsapp">
                                </div>
                            </div>
                            <div class='form-row'>
                                <div class="col-12 mx-0 my-2 p-0">
                                    <textarea class='form-control  border-top-0 border-left-0 border-right-0' name='message' placeholder="Message" maxlength="200" rows='4'></textarea>
                                </div>
                            </div>
                            <p class="card-text mt-3">Please make sure your email address, or phone number is right. We will reach you as soon as possible .</p>
                            <p class="card-text"><small class="text-muted">*can't be blank</small></p>
                            <button class="btn mt-4 pl-5 pr-5" style='background-color: #4d8089; color: white;'>SEND</button>

                        </form>                    
                    </div>
                </div>

            </div>
        </div>
    </div>
  <Footer/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Navbar, Footer
    },    
}
</script>
<style scoped>
    .content{
        max-width: 800px;
        margin: 0px auto;
    }
    
</style>