<template>
    <div id="mainBG" class='cover-container' 
        :style="`
            background-image:url(${imageData.imageUrl}); 
            background-position:right center; 
            background-size:cover;
            overflow: hidden;
            height:${imageData.height};
        `"
        >
        <div class='row'>
        <div class=''>
            <p class='lead text-center'>
            </p>
        </div>
        </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-core'
export default {
    props:["imageData"],
    setup(props) {
        const imageData = ref(props.imageData)
        return { imageData }
    }
}
</script>
<style scoped>

    
</style>