<template>
  <Navbar/>
    <div class='container py-3' v-if="!destination">loading...</div>
    <div class='container py-3 mb-5' v-if="destination">
        
        <section v-if="filteredDestination.length">
            <h1 class='mt-4 text-center'>Explore Our Destination</h1>
            <div class='row'>
                <div class="mt-4 destination-container text-white my-4"  v-for="(place, index) in filteredDestination" :key="index"
                :style="`background-image:url(${place.coverUrl})`" 
                style="background-size: cover; background-position:center;">
                    <router-link :to="{name:'DestinationDetail', params:{id: place.id, hashtags:place.hashtags}}" class="destination-img-overlay">
                        <div class="destination-img-overlay">
                            <p class="destination-title font-utama p-0 m-0">{{place.name}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>
        
        <section v-else>
            <h4 style="margin-top:50px">Updating and Gearing Up</h4>
            <p>We' re sorry our destination page was on maintenance</p>
        </section>

    </div>
  <Footer/>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import { computed } from '@vue/runtime-core'

export default {
    components: {
        Navbar, Footer
    },
    setup(props) {
        const {documents:destination} = getCollection('destination')
        // console.log(destination)
        const { document:setting } = getDocument('setting','contactSetting')

        const filteredDestination = computed(()=>{
            return destination.value.filter((place) => {
                if (place.status_publish) {
                    return place
                }
            })
        })

        return {destination, filteredDestination, setting}
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');
.font-utama{
    font-family: 'Shadows Into Light', cursive;
}

a{
    text-decoration: none;
    color: inherit;
}

.destination-title{
    font-size: 2.6em;
    /* text-shadow: 2px 2px #ddd; */
    letter-spacing: 7px;
    text-transform: uppercase;
    padding-bottom: 0px;
}

.destination-main-text{
    font-size: 1.5em;
}

.destination-container{
    width: 100%;
    position: relative;
    height: 10rem;
    border-radius: 5px;
    position: relative;
}

.destination-img-overlay{
    background: transparent;
    width: 100%;
    height: 100%;
}

.destination-img-overlay p{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 50px;
}

</style>