<template>
  <nav class="navbar navbar-expand-xl navbar-light">
    <a class="navbar-brand" href="/"><img src="../assets/logo_panjang-01.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse px-2 py-3 p-sm-0" id="navbarsExampleDefault">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link"  to="/"><i class="fa fa-home"></i></router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link"  to="/destination">Destination</router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link"  to="/contact">Contact</router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link"  to="/gallery">Gallery</router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link" to="/about">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-1 p-sm-2 nav-link"  to="/blog">Blog</router-link>
          </li>
        </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    // msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.navbar-brand img{
  max-width: 300px;
}

nav{
  background: #f8f9fa;
}

@media (max-width: 576px) {
  .navbar-brand img{
    max-width: 150px;
  }
}

@media (orientation: portrait) {
  nav{
    background: rgba(248, 249, 250, 0.4);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
  }
}

</style>
