<template>

  <div class="modal fade" id="modalBlank" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

      <!--Content-->
      <div class="modal-content">
        <div class="modal-body">
          <div v-html="modalContent"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Close</button>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props:['content'],
  setup(props) {
    const modalContent = ref(props.content)

    return {modalContent}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
