import { ref } from "vue"
import { projectFirestore } from "../firebase/config"


const useCollection = (collection) => {
    
    const error = ref(null)
    const isPending = ref(false)

    const addDoc = async (doc) => {
        error.value = null

        try {
            isPending.value = true
            const res = await projectFirestore.collection(collection).add(doc)
            isPending.value = false
            return res
        } catch (err) {
            isPending.value = false
            console.log(err.message);
            error.value = "couldn't send the message right now"
        }
    }

    return {error, addDoc}

}

export default useCollection